<div>
    <div class="row justify-content-center mt-4">
        <div class="col-md-7">
            <h1 jhiTranslate="reset.request.title" class="mb-3">Reset your password</h1>
            <div class="alert alert-info" jhiTranslate="reset.request.messages.passwordExpired">
                Your password has expired. Please change it now.
            </div>

            <div class="alert alert-danger" jhiTranslate="reset.request.messages.notfound" *ngIf="errorEmailNotExists">
                <strong>Email address isn't registered!</strong> Please check and try again.
            </div>
            <div class="alert alert-danger" jhiTranslate="reset.request.messages.notActivated" *ngIf="userNotActivated">
                <strong>User exist but it's not activated</strong> Please contact admin
            </div>

            <div class="alert alert-warning" *ngIf="!success">
                <p jhiTranslate="reset.request.messages.info" class="mb-0">Enter the email address you used to register.</p>
            </div>

            <div class="alert alert-success" *ngIf="success === 'OK'">
                <p jhiTranslate="reset.request.messages.success">Check your emails for details on how to reset your password.</p>
            </div>

            <form *ngIf="!success" name="form" role="form" (ngSubmit)="requestReset()" #resetRequestForm="ngForm">
                <div class="form-group">
                    <label class="form-control-label" for="email" jhiTranslate="global.form.email">Email</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="{{'global.form.email.placeholder' | translate}}"
                           [(ngModel)]="resetAccount.email" minlength=5 maxlength=100 #emailInput="ngModel" email required>
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.required" jhiTranslate="global.messages.validate.email.required">
                            Your email is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.email" jhiTranslate="global.messages.validate.email.invalid">
                            Your email is invalid.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.minlength" jhiTranslate="global.messages.validate.email.minlength">
                            Your email is required to be at least 5 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.maxlength" jhiTranslate="global.messages.validate.email.maxlength">
                            Your email cannot be longer than 100 characters.
                        </small>
                    </div>
                </div>
                <button  type="submit" [disabled]="resetRequestForm.form.invalid" class="btn btn-primary float-right" jhiTranslate="reset.request.form.button">Reset</button>
            </form>

        </div>
    </div>
</div>
