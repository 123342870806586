<div>
    <div class="justify-content-center">
        <div class="panel">

            <h2 jhiTranslate="settings.security.title">Ustawienia zabezpieczeń</h2>

            <jhi-alert-error></jhi-alert-error>
            <jhi-alert></jhi-alert>


            <div class="form-group">
                <label class="form-control-label" for="password" jhiTranslate="settings.security.password">Hasło</label>
                <input [readonly]="true" type="password" class="form-control" id="password" name="password"
                       [value]="'********'" />

            </div>
            <button class="btn-link btn" routerLink="/password-reset" jhiTranslate="settings.security.changePassword">Zmień hasło</button>

            <div class="form-group phoneNumber-form-group">
                <label class="form-control-label" for="phoneNumber" jhiTranslate="global.form.phoneNumber">Numer
                    telefonu</label>

                <ngx-intl-tel-input
                    id="phoneNumber"
                    [cssClass]="'custom'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    customPlaceholder="XXXXXXXXX"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true"
                    [selectedCountryISO]="CountryISO.Poland"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    inputId="phoneNumber"
                    name="phoneNumber"
                    [(ngModel)]="securitySettings.phoneNumber"
                ></ngx-intl-tel-input>

            </div>
            <button class="btn-link btn" routerLink="/phone-reset" jhiTranslate="settings.security.changePhoneNumer">Zmień numer telefonu</button>


            <div class="form-group">
                <label class="form-control-label" for="question" jhiTranslate="settings.form.question">Pytanie
                    pomocnicze</label>
                <input disabled="true" class="form-control" id="question" name="question"
                       [value]="securitySettings.securityQuestion==null ? '':'********'" />

            </div>

            <button class="btn-link btn" routerLink="/question-reset" jhiTranslate="settings.security.changeQuestion">Zmień pytanie pomocnicze
            </button>


        </div>
    </div>

</div>
