import {Component, OnInit} from "@angular/core";
import {Category} from "./category.model";
import {MatDialogRef} from "@angular/material/dialog";
import {CategoryService} from "../category.service";

@Component({
    selector: 'jhi-category-dialog',
    templateUrl: './category-dialog.component.html',
})
export class CategoryDialogComponent implements OnInit {

    displayedColumns: string[] = ['name', 'active', 'action'];

    name: string = '';
    categories: Category[];
    category: Category;

    constructor(public dialogRef: MatDialogRef<CategoryDialogComponent>,
                private categoryService: CategoryService) {
    }

    ngOnInit(): void {
        this.loadCategories();
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        if(this.category == null) {
            this.category = new Category();
            this.category.active = true;
        }
        this.category.name = this.name;
        this.categoryService.addCategory(this.category).subscribe(() => {
           this.name = null;
           this.category = null;
           this.loadCategories();
        });
    }

    editCategory(category: Category): void {
        this.category = category;
        this.name = this.category.name;
    }

    changeStateCategory(category: Category): void {
        this.category = category;
        this.category.active = !this.category.active;
        this.categoryService.addCategory(this.category).subscribe(() => {
            this.name = null;
            this.category = null;
            this.loadCategories();
        });
    }

    private loadCategories(): void {
        this.categoryService.getCategories(false).subscribe(resp => {
           this.categories = resp.body;
        });
    }
}
