
import {tap} from 'rxjs/operators';
import { JhiEventManager }from '@upside-cloud/ng-jhipster';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private eventManager: JhiEventManager) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {}, (err: any) => {
            if (!(err instanceof HttpErrorResponse)) {
                return;
            }

            if (err.status === 429) {
                return;
            }

            if (err.status === 401 && (err.message === '' || (err.url && err.url.endsWith('/api/account')))) {
                return;
            }

            if (this.eventManager !== undefined) {
                this.eventManager.broadcast({name: 'vdrApp.httpError', content: err});
            }
        }));
    }
}
