import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
    selector: '[specialIsAlphaNumeric]'
})
export class SpecialCharacterDirective {

    allowChar = /^[\p{Letter}\p{Number}!@#$%&()_\-={}\[\],.]+$/u;
    notAllowChar = /[\\/:<>?*"“|]/g;

    constructor(private el: ElementRef) {
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event) {
        return new RegExp(this.allowChar).test(event.key) && !new RegExp(this.notAllowChar).test(event.key);
    }

    @HostListener('paste', ['$event'])
    blockPaste(event: ClipboardEvent) {
        this.validateFields(event);
    }

    validateFields(event: ClipboardEvent) {
        event.preventDefault();
        const pasteDate = event.clipboardData.getData('text/plain').replace(this.notAllowChar, '');
        document.execCommand('insertHTML', false, pasteDate);
    }
}
