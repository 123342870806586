import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';

import {PasswordResetInitService} from './password-reset-init.service';
import {EMAIL_NOT_FOUND_TYPE, USER_NOT_ACTIVATED} from '../../../shared';

@Component({
    selector: 'jhi-password-reset-init',
    templateUrl: './password-reset-init.component.html',
    styleUrls: ['./password-init.scss']
})
export class PasswordResetInitComponent implements OnInit, AfterViewInit {
    error: string;
    errorEmailNotExists: string;
    resetAccount: any;
    success: string;
    userNotActivated: string;

    constructor(private passwordResetInitService: PasswordResetInitService,
                private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.resetAccount = {};
    }

    ngAfterViewInit() {
        this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#email')).focus();
        // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#email'), 'focus', []);
    }

    requestReset() {
        this.error = null;
        this.errorEmailNotExists = null;

        this.passwordResetInitService.save(this.resetAccount.email).subscribe(() => {
            this.success = 'OK';
        }, (response) => {
            this.success = null;
            if (response.status === 400 && response.error.type === EMAIL_NOT_FOUND_TYPE) {
                this.errorEmailNotExists = 'ERROR';
            } else if (response.status === 400 && response.error.type === USER_NOT_ACTIVATED) {
                this.userNotActivated = 'ERROR';
            } else {
                this.error = 'ERROR';
            }
        });
    }
}
