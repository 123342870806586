<div fxLayout="row">
    <span mat-dialog-title fxFlex="90%" *ngIf="title">{{title}}</span>
</div>
<div mat-dialog-content>
    <jhi-alert-error></jhi-alert-error>
    <div fxLayout="column" fxLayoutAlign="center">
        <p-progressBar [value]="getProgressPercentage()" showValue="true"></p-progressBar>
        <span *ngIf="statusLine">{{ statusLine }}</span>
    </div>
    <a #link></a>
</div>
