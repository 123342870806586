import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';


export interface GenericProgressDialogData {
    total: number;
    current: number;
    title: string;
}


@Component({
    selector: 'generic-progress-dialog',
    templateUrl: 'generic-progress-dialog.component.html',
    styleUrls: []
})
export class GenericProgressDialog implements AfterViewInit {
    statusLine: string;
    title: string;

    constructor(
        public dialogRef: MatDialogRef<GenericProgressDialog>,
        @Inject(MAT_DIALOG_DATA) public data: GenericProgressDialogData,
        private translateService: TranslateService,
    ) {
        this.title = data.title;
    }

    getProgressPercentage() {
        if (this.data.total === 0) {
            return 0;
        }

        return Math.floor(this.data.current * 100 / this.data.total);
    }

    ngAfterViewInit(): void {
    }
}
