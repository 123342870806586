<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" [fullScreen]="true" size="medium" color="red" type="ball-atom"></ngx-spinner>


<div *ngIf="registrationKey" class="register">
    <div class="ml-0 mr-0 row justify-content-center mt-4">
        <div class="col-md-8">

            <h1 class="mt-2 mb-2" jhiTranslate="register.title">Registration</h1>

            <div class="alert alert-success mb-3" *ngIf="success" jhiTranslate="register.messages.success">
                <strong>Registration saved!</strong> Please check your email for confirmation.
            </div>

            <div class="alert alert-danger mb-3" *ngIf="validationFailed" jhiTranslate="register.messages.error.validation">
                <strong>Registration failed!</strong> Check fields for errors.
            </div>

            <div class="alert alert-danger mb-3" *ngIf="error" jhiTr anslate="register.messages.error.fail">
                <strong>Registration failed!</strong> Please try again later.
            </div>

            <div class="alert alert-danger mb-3" *ngIf="errorUserExists" jhiTranslate="register.messages.error.userexists">
                <strong>Login name already registered!</strong> Please choose another one.
            </div>

            <div class="alert alert-danger mb-3" *ngIf="errorEmailExists" jhiTranslate="register.messages.error.emailexists">
                <strong>Email is already in use!</strong> Please choose another one.
            </div>

            <div class="alert alert-danger mb-3" *ngIf="doNotMatch" jhiTranslate="global.messages.error.dontmatch">
                The password and its confirmation do not match!
            </div>
            <div class="alert alert-danger mb-3" *ngIf="keyExpired" jhiTranslate="register.messages.error.expired">
                Registration key has expired. Contact your administrator to be invited again
            </div>
        </div>
    </div>
    <form name="form" role="form" (ngSubmit)="register()" [formGroup]="registrationForm" *ngIf="!formHidden">

        <div class="ml-0 mr-0 row justify-content-center">

            <div class="col-md-8 panel">
                <div class="two-controls">
                    <h6 class="mb-2">{{ 'settings.panel.basic' | translate }}</h6>


                    <div class="lang-select">
                        <mat-select [value]="currentLanguage" (selectionChange)="changeLanguage($event)">
                            <mat-select-trigger>
                                <span class="flag flag-{{currentLanguage}}"> </span>
                                <span class="ml-2">{{ currentLanguage | findLanguageFromKey }}</span>
                            </mat-select-trigger>
                            <mat-option [value]="null">Wybierz język</mat-option>
                            <mat-option *ngFor="let language2 of languages" [value]="language2">
                                <span class="flag flag-{{language2}}"></span>
                                <span class="ml-2">{{ language2 | findLanguageFromKey }}</span>
                            </mat-option>
                        </mat-select>

                    </div>
                </div>


                <div class="two-controls">
                    <div class="form-group required" required>
                        <label class="form-control-label" for="firstName" jhiTranslate="settings.form.firstname">First
                            Name</label>
                        <input type="text" class="form-control" id="firstName" name="firstName"
                               placeholder="{{'settings.form.firstname.placeholder' | translate}}"
                               formControlName="firstName" minlength=1 maxlength=50 required>
                        <div
                            *ngIf="registrationForm.get('firstName')?.touched && registrationForm.get('firstName')?.invalid">
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('firstName').errors?.required"
                                   jhiTranslate="settings.messages.validate.firstname.required">
                                Your first name is required.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('firstName').errors?.minlength"
                                   jhiTranslate="settings.messages.validate.firstname.minlength">
                                Your first name is required to be at least 1 character.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('firstName').errors?.maxlength"
                                   jhiTranslate="settings.messages.validate.firstname.maxlength">
                                Your first name cannot be longer than 50 characters.
                            </small>
                        </div>
                    </div>

                    <div class="form-group required">
                        <label class="form-control-label" for="lastName" jhiTranslate="settings.form.lastname">Last
                            Name</label>
                        <input type="text" class="form-control" id="lastName" name="lastName"
                               placeholder="{{'settings.form.lastname.placeholder' | translate}}"
                               formControlName="lastName" minlength=1 maxlength=50
                               required>
                        <div
                            *ngIf="registrationForm.get('lastName')?.touched && registrationForm.get('lastName')?.invalid">
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('lastName').errors?.required"
                                   jhiTranslate="settings.messages.validate.lastname.required">
                                Your last name is required.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('lastName').errors?.minlength"
                                   jhiTranslate="settings.messages.validate.lastname.minlength">
                                Your last name is required to be at least 1 character.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('lastName').errors?.maxlength"
                                   jhiTranslate="settings.messages.validate.lastname.maxlength">
                                Your last name cannot be longer than 50 characters.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="two-controls">
                    <div class="form-group required">
                        <label class="form-control-label" for="company" jhiTranslate="settings.form.company">Company
                            Name</label>
                        <input type="text" class="form-control" id="company" name="company"
                               placeholder="{{'settings.form.company' | translate}}"
                               formControlName="company" minlength=1 maxlength=50 required
                        >
                    </div>


                    <div class="form-group required">
                        <label class="form-control-label" for="companyTitle" jhiTranslate="settings.form.companyTitle">Company
                            Title</label>
                        <input type="text" class="form-control" id="companyTitle" name="companyTitle"
                               placeholder="{{'settings.form.companyTitle' | translate}}"
                               formControlName="companyTitle" minlength=1 maxlength=50 required
                        >
                    </div>
                </div>
                <div class="form-group required" style="width:45%">
                    <label class="form-control-label" for="phoneNumber" jhiTranslate="settings.form.phoneNumber">Last
                        Name</label>

                    <ngx-intl-tel-input
                        id="phoneNumber"
                        [cssClass]="'custom'"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        customPlaceholder="XXXXXXXXX"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true"
                        [selectedCountryISO]="CountryISO.Poland"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        inputId="phoneNumber"
                        name="phoneNumber"
                        formControlName="phoneNumber"
                    ></ngx-intl-tel-input>
                    <div
                        *ngIf="registrationForm.get('phoneNumber')?.touched && registrationForm.get('phoneNumber')?.errors">
                        <small class="form-text text-danger" jhiTranslate="entity.validation.required">
                            Your last name is required.
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-8 panel mt-4">
                <h6 class="mb-2">{{ 'userManagement.detailPanel.tabs.security' | translate | uppercase }}</h6>
                <div class="two-controls">

                    <div class="form-group required">
                        <label class="form-control-label" for="password" jhiTranslate="global.form.newpassword">New
                            password</label>
                        <div class="input-group">
                            <input [type]="password1Show ? 'text' : 'password'" class="form-control" id="password"
                                   name="password" #password (keyup)="onPasswordInputChange(password.value)"
                                   placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                                   formControlName="password" minlength=12 maxlength=40
                                   pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{12,40}$"
                                   required>
                            <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fa"
                                     [ngClass]="{'fa-eye-slash': !password1Show,'fa-eye': password1Show}"
                                     (click)="password1Show = !password1Show"
                                  ></i>
                                </span>
                            </div>
                        </div>
                        <jhi-password-strength-bar [passwordToCheck]="password.value"></jhi-password-strength-bar>
                        <div style="font-size: smaller" class="flex align-items-center">
                            <span jhiTranslate="register.password.label">Hasło powinno zawierać:</span>
                            <ul>
                                <li [style.color]="passwordElements?.hasCorrectLength ? 'green' : null "jhiTranslate="register.password.charactersCount">Przynajmniej 12 znaków</li>
                                <li [style.color]="passwordElements?.hasBigLetter  ? 'green' : null " jhiTranslate="register.password.capitalLetter">Duzą literę</li>
                                <li [style.color]="passwordElements?.hasSmallLetter  ? 'green' : null " jhiTranslate="register.password.smallLetter">Małą literę</li>
                                <li [style.color]="passwordElements?.hasDigit  ? 'green' : null " jhiTranslate="register.password.digit">Cyfrę</li>
                                <li [style.color]="passwordElements?.hasSpecialCharacter  ? 'green' : null " jhiTranslate="register.password.specialCharacter">Znak specjalny (!@#$%^&*()_+\-=\[\]&#123;};':"\\|,.<>\/?])</li>
                            </ul>
                        </div>
                        <div
                            *ngIf="registrationForm.get('password')?.touched && registrationForm.get('password')?.invalid">
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('password').errors?.required"
                                   jhiTranslate="global.messages.validate.newpassword.required">
                                Your password is required.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('password').errors?.minlength"
                                   jhiTranslate="global.messages.validate.newpassword.minlength">
                                Your password is required to be at least 12 characters.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('password').errors?.maxlength"
                                   jhiTranslate="global.messages.validate.newpassword.maxlength">
                                Your password cannot be longer than 40 characters.
                            </small>
                            <small class="form-text text-danger" *ngIf="registrationForm.get('password').errors?.pattern"
                                   jhiTranslate="global.messages.validate.newpassword.pattern">
                                Your password must contain minimum twelve characters, at least one uppercase letter, one
                                lowercase letter, one number and one special character:.
                            </small>
                        </div>
                    </div>
                    <div class="form-group required">
                        <label class="form-control-label" for="confirmPassword"
                               jhiTranslate="global.form.confirmpassword">New
                            password confirmation</label>
                        <div class="input-group">
                            <input [type]="password2Show ? 'text' : 'password'" class="form-control"
                                   id="confirmPassword"
                                   name="confirmPassword"
                                   placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                                   formControlName="confirmPassword" minlength="12" maxlength=40 required>
                            <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fa"
                                     [ngClass]="{'fa-eye-slash': !password2Show,'fa-eye': password2Show}"
                                     (click)="password2Show = !password2Show"
                                  ></i>
                                </span>
                            </div>
                        </div>
                        <div
                            *ngIf="registrationForm.get('confirmPassword')?.touched && registrationForm.get('confirmPassword')?.invalid">
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('confirmPassword').errors?.required"
                                   jhiTranslate="global.messages.validate.confirmpassword.required">
                                Your confirmation password is required.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="registrationForm.get('confirmPassword').errors?.notMatching"
                                   jhiTranslate="global.messages.validate.confirmpassword.minlength">
                                Your confirmation password is required to be at least 12 characters.
                            </small>
                            <small class="form-text text-danger" *ngIf="registrationForm.get('confirmPassword').errors.maxlength"
                                   jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                                Your confirmation password cannot be longer than 40 characters.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="two-controls">
                    <div class="form-group required">
                        <label class="form-control-label" for="question" jhiTranslate="settings.form.question">Pytanie
                            pomocnicze</label>
                        <select class="form-control" id="question" name="question"
                                formControlName="securityQuestion">
                            <option value="" selected disabled>Wybierz</option>
                            <option *ngFor="let securityQuestion of securityQuestions"
                                    [value]="securityQuestion.question">{{ securityQuestion.question | translate }}
                            </option>
                        </select>
                    </div>


                    <div class="form-group required">
                        <label for="question-answer" jhiTranslate="settings.form.questionAnswer">Odpowiedź na
                            pytanie</label>
                        <input type="text" class="form-control" name="question-answer" id="question-answer"
                               placeholder="" required
                               formControlName="securityAnswer"/>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <div class="alertinfo">
                        <mat-icon>alert</mat-icon>
                        <span jhiTranslate="register.form.fieldRequiredHint">* Pole wymagane</span>
                    </div>
                    <button type="submit" class="btn btn-primary float-right"
                            jhiTranslate="register.form.button">Register
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
