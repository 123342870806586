import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from '@upside-cloud/ng-jhipster';

import {Question, QuestionRequest} from './question.model';
import {QuestionPopupService} from './question-popup.service';
import {QuestionService} from './question.service';
import {Principal, User, UserService} from '../../shared';
import {DocumentVdr, DocumentVdrService} from '../document-vdr';
import {RecipientService} from './recipient.service';
import {Recipient} from './recipient.model';
import {DocumentSearchRowCriteriaDTO} from '../directory-document/dto/document-search-row-criteria-dto.model';
import {SearchIn} from '../directory-document/dto/search-in.enum';
import {DocumentSearchRowDTO} from '../directory-document/dto/document-search-row-dto.model';
import {FileUpload} from 'primeng/fileupload';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {Category} from './category/category.model';
import {CategoryService} from './category.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UploadDialogComponent} from '../directory-document/upload.component';
import {DirectoryChange} from '../directory-document/document-list.component';

@Component({
    selector: 'jhi-question-dialog',
    templateUrl: './question-dialog.component.html',
    styleUrls: ['question.scss']

})
export class QuestionDialogComponent implements OnInit {
    question: QuestionRequest;
    isSaving: boolean;
    users: User[];
    dropdownSettings: IDropdownSettings = {};
    recipients: Recipient[] = [];
    categories: Category[];

    documents: DocumentSearchRowDTO[];
    fileName = '';

    attachments: any[] = [];
    isShowDW: boolean;
    contentLength = 0;

    @ViewChild('fileInput') fileInput: FileUpload;
    dropdownList: ({ item_id: number; item_text: string })[];
    selectedItems: ({ item_id: number; item_text: string })[];

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private questionService: QuestionService,
        private recipientService: RecipientService,
        private userService: UserService,
        private documentService: DocumentVdrService,
        private eventManager: JhiEventManager,
        private principal: Principal,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<QuestionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: QuestionRequest,
        private categoryService: CategoryService,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.question = this.data;
        this.isSaving = false;
        this.isShowDW = false;
        this.question.recipientsTO = [];
        this.question.recipientsDW = [];

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'groupId',
            textField: 'recipientName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };


        // @ts-ignore
        for (const element of document.getElementsByClassName('pi-caret-down')) {
            element.classList.add('pi-caret-up');
            element.classList.remove('pi-caret-down');

        }

        this.loadCategories();
    }

    onItemSelect(item: any) {
        console.log(item);
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    suggestRecipients(event: any, recipientTypeParam: string) {
        const query = event.query;

        this.suggestRecipients2(query, recipientTypeParam);
    }

    private suggestRecipients2(query, recipientTypeParam: string) {
        if (!query) {
            query = '';
        }
        this.recipientService.suggestRecipients({query, recipientType: recipientTypeParam})
            .subscribe((res: HttpResponse<Recipient[]>) => {
                this.recipients = res.body;
            }, (res: HttpErrorResponse) => this.onError(res.message));
    }

    suggestDocument(event: any) {
        let query = event.query;

        if (!query) {
            query = '';
        }

            const queryObject = new DocumentSearchRowCriteriaDTO();
            queryObject.phrase = query;
            queryObject.searchIn = SearchIn[SearchIn.TITLES.toString()];

            this.documentService.getSearchRowsWithCriteria(queryObject, null)
                .subscribe(
                    (res: DocumentSearchRowDTO[]) => this.documents = res,
                    (res: HttpErrorResponse) => this.onError(res.message)
                );

    }

    cannotShareQuestion() {
        return !this.principal.hasAnyAuthorityDirect(['QA_SHARE', 'QA_DELETE']);
    }

    isAdminLimited() {
        return !this.principal.hasAnyAuthority(['ADMIN_LIMITED']);
    }


    startUpload() {
        const input: HTMLInputElement = this.fileInput.advancedFileInput.nativeElement;
        input.click();
        // const dialogRef = this.dialog.open(UploadDialogComponent, {
        //     width: '580px',
        //     data: {directoryId: this.directoryId},
        //     disableClose: true,
        //     hasBackdrop: true
        // });
        //
        // dialogRef.afterClosed().subscribe(() => {
        //
        // });
    }

    clear() {
        this.dialogRef.close();
    }

    save() {
        this.isSaving = true;

        this.question.recipients = [];

        for (const recipient of this.question.recipientsDW) {
            this.question.recipients.push(recipient);
        }

        for (const recipient of this.question.recipientsTO) {
            this.question.recipients.push(recipient);
        }

        if (this.question.document !== undefined) {
            this.question.documentId = this.question.document.documentId;
        }

        if (this.question.id !== undefined) {
            this.subscribeToSaveResponse(
                this.questionService.update(this.question));
        } else {
            this.subscribeToSaveResponse(
                this.questionService.create(this.question, this.attachments));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<Question>>) {
        result.subscribe((res: HttpResponse<Question>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    private onSaveSuccess(newQuestion: Question) {
        this.eventManager.broadcast({name: 'questionListModification', content: newQuestion});
        this.isSaving = false;
        this.dialogRef.close();
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    trackDocumentById(index: number, item: DocumentVdr) {
        return item.id;
    }

    myUploader(event) {
        this.attachments = [];
        for (const file of event.target.files) {
            if (file) {
                this.fileName = file.name;
                this.attachments.push(file);
            }
        }
    }

    cancelUpload(): void {
        this.fileName = '';
        this.attachments = [];
    }

    mySelect(event) {
    }

    loadCategories(): void {
        this.categoryService.getCategories(true).subscribe((resp) => this.categories = resp.body);
    }

    checkContent($event: any): void {
        this.contentLength = $event.textValue.length;
    }

}

@Component({
    selector: 'jhi-question-popup',
    template: ''
})
export class QuestionPopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private questionPopupService: QuestionPopupService
    ) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if (params['id']) {
                this.questionPopupService
                    .open(QuestionDialogComponent as Component, params['id']);
            } else {
                this.questionPopupService
                    .open(QuestionDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }

}
