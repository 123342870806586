<div class="modal-header">
    <div class="logo2">
        <img src="/content/images/secudo_watermark2.png"/>
    </div>
    <div class="lang-select">


        <mat-select [value]="currentLanguage" (selectionChange)="changeLanguage($event)" >
            <mat-select-trigger>
                <span class="flag flag-{{currentLanguage}}"> </span>
                <span class="ml-2">{{currentLanguage | findLanguageFromKey}}</span>
            </mat-select-trigger>
            <mat-option [value]="null">Wybierz język</mat-option>
            <mat-option *ngFor="let language2 of languages" [value]="language2">
                <span class="flag flag-{{language2}}"></span>
                <span class="ml-2">{{language2 | findLanguageFromKey}}</span>
            </mat-option>
        </mat-select>

    </div>
</div>

<div class="header2">
    <h4 class="modal-title" jhiTranslate="login.title">Sign in</h4>
</div>

<div class="modal-body">
    <div class="row justify-content-center">
        <div class="alert alert-success" *ngIf="message">
                    <span
                        jhiTranslate="{{message}}"><strong>message</strong> Please </span>

        </div>
        <div class="col-md-8">
            <div class="alert alert-danger" *ngIf="authenticationError"
                 jhiTranslate="login.messages.error.authentication">
                <strong>Failed to sign in!</strong> Please check your credentials and try again.
            </div>
            <div class="alert alert-danger" *ngIf="rateLimited"
                 jhiTranslate="login.messages.error.rateLimited">
                <strong>Failed to sign in!</strong> Please check your credentials and try again.
            </div>
        </div>
        <div class="text-right" *ngIf="languages.length > 1">


        </div>
        <div class="col-md-8">
            <form class="form" role="form" (ngSubmit)="login()">
                <div class="form-group">
                    <label for="username" jhiTranslate="global.form.email">Login</label>
                    <input type="text" class="form-control" name="username" id="username"
                           placeholder="{{'global.form.email.placeholder' | translate}}"
                           [(ngModel)]="username">
                </div>
                <div class="form-group">
                    <label for="password" jhiTranslate="login.form.password">Password</label>
                    <div class="input-group">
                        <input [type]="passwordShow ? 'text' : 'password'" class="form-control" id="password"
                               name="password" #confirmPasswordInput="ngModel"
                               placeholder="{{'login.form.password.placeholder' | translate}}"
                               [(ngModel)]="password">

                        <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="fa"
                                         [ngClass]="{'fa-eye-slash': !passwordShow,'fa-eye': passwordShow}"
                                         (click)="passwordShow = !passwordShow"
                                      ></i>
                                    </span>
                        </div>
                    </div>

                </div>

                <div style="width:100%;text-align: center;margin-top:50px;margin-bottom: 50px">
                    <button type="submit" class="btn btn-primary signin-button" [disabled]="loginInProgress"
                            style="margin-left:auto;margin-right:auto" jhiTranslate="login.form.button">Sign in
                    </button>
                </div>

            </form>
            <p></p>
            <div class="forget">
                <a (click)="requestResetPassword()" jhiTranslate="login.password.forgot">Did you forget your
                    password?</a>
            </div>
        </div>
    </div>
</div>
