<div class="row justify-content-center project2">
    <div class="project2-page col-md-8">
        <jhi-alert-error></jhi-alert-error>

        <div class="tabs" *ngIf="project">
            <form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm"
                  novalidate>
                <mat-tab-group>

                    <mat-tab label="{{ 'vdrApp.project.tabs.generalSettings' | translate }}" class="custom-mat-tab">
                        <div class="content-scroll">
                            <div class="panel">
                                <h2 jhiTranslate="vdrApp.project.properties.generalSettigns">Dane</h2>


                                <div class="form-group">
                                    <label class="form-control-label" for="name"
                                           jhiTranslate="vdrApp.project.properties.name">
                                        Nazwa</label>
                                    <input type="text" class="form-control" id="name" name="name"
                                           [(ngModel)]="project.name" minlength=1 maxlength=50 #lastNameInput="ngModel"
                                           required>
                                </div>

                                <div class="form-group">
                                    <label class="form-control-label" for="www"
                                           jhiTranslate="vdrApp.project.properties.url">
                                        PROJECT URL</label>
                                    <input [disabled]="true" type="text" class="form-control" id="www" name="www"
                                           [(ngModel)]="project.www" minlength=1 maxlength=50 #lastNameInput="ngModel"
                                    >
                                </div>


                                <div class="d-flex flex-row">
                                    <div>
                                        <label>{{'vdrApp.project.properties.startDate' | translate}}</label>
                                        <div>
                                            <mat-form-field>
                                                <input name="startDate"
                                                       matInput
                                                       [matDatepicker]="startDatePicker"
                                                       [(ngModel)]="project.startDate"
                                                       (click)="$event.stopPropagation();">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="startDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startDatePicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div>
                                        <label>{{'vdrApp.project.properties.endDate' | translate}}</label>
                                        <div>
                                            <mat-form-field>
                                                <input name="endDate"
                                                       matInput
                                                       [matDatepicker]="endDatePicker"
                                                       [(ngModel)]="project.endDate"

                                                       (click)="$event.stopPropagation();">
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="endDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endDatePicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="panel">
                                <h2 jhiTranslate="vdrApp.project.properties.title">Properties</h2>
                                <div class="flex-d flex-column">
                                    <label jhiTranslate="vdrApp.project.properties.automaticIndexing"></label>
                                    <div class="btn-group flex-btn-group-container mt-2">
                                        <button type="button"
                                                class="btn btn-light btn-sm" (click)="changeAutomaticIndexing(true)"
                                                [ngClass]="{'btn-primary': project.automaticIndexing}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.yes">Yes</span>
                                        </button>
                                        <button type="button"
                                                replaceUrl="true"
                                                queryParamsHandling="merge"
                                                class="btn btn-light btn-sm" (click)="changeAutomaticIndexing(false)"
                                                [ngClass]="{'btn-primary': !project.automaticIndexing}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.no">No</span>
                                        </button>
                                    </div>
                                </div>


                                <div class="flex-d flex-column  mt-3">
                                    <label jhiTranslate="vdrApp.project.properties.versioning"></label>
                                    <div class="btn-group flex-btn-group-container mt-2">
                                        <button type="button"
                                                class="btn btn-light btn-sm" (click)="changeVersioning(true)"
                                                [ngClass]="{'btn-primary': project.versioning}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.yes">Yes</span>
                                        </button>
                                        <button type="button"
                                                replaceUrl="true"
                                                queryParamsHandling="merge"
                                                class="btn btn-light btn-sm" (click)="changeVersioning(false)"
                                                [ngClass]="{'btn-primary': !project.versioning}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.no">No</span>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div class="panel">
                                <h2>Q&A</h2>
                                <div class="flex-d flex-column  mt-3">
                                    <label jhiTranslate="vdrApp.project.properties.limit.qa"></label>
                                    <div class="btn-group flex-btn-group-container mt-2">
                                        <button type="button"
                                                class="btn btn-light btn-sm" (click)="changeQuestionLimit(true)"
                                                [ngClass]="{'btn-primary': project.questionLimit}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.yes">Yes</span>
                                        </button>
                                        <button type="button"
                                                replaceUrl="true"
                                                queryParamsHandling="merge"
                                                class="btn btn-light btn-sm" (click)="changeQuestionLimit(false)"
                                                [ngClass]="{'btn-primary': !project.questionLimit}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.no">No</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="panel">
                                <h2 jhiTranslate="vdrApp.project.properties.security">Bezpieczeństwo</h2>
                                <div class="flex-d flex-column mt-3">
                                    <label jhiTranslate="vdrApp.project.properties.authentication">Two phase
                                        authentication</label>
                                    <div class="btn-group flex-btn-group-container mt-2">
                                        <button type="button"
                                                class="btn btn-light btn-sm"
                                                (click)="changeTwoPhaseAuthentication(true)"
                                                [ngClass]="{'btn-primary': project.twoPhaseAuthentication}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.yes">Yes</span>
                                        </button>
                                        <button type="button"
                                                replaceUrl="true"
                                                queryParamsHandling="merge"
                                                class="btn btn-light btn-sm"
                                                (click)="changeTwoPhaseAuthentication(false)"
                                                [ngClass]="{'btn-primary': !project.twoPhaseAuthentication}">
                                            <span class="d-none d-md-inline"
                                                  jhiTranslate="global.no">No</span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="panel">
                                <h2 jhiTranslate="vdrApp.project.properties.therms">Accept term of use:</h2>

                                <div class="btn-group flex-btn-group-container mt-2">
                                    <button type="button"
                                            class="btn btn-light btn-sm" (click)="changeTherm('FIRST_LOGIN')"
                                            [ngClass]="{'btn-primary': project.therm =='FIRST_LOGIN'}">
                                        <span class="d-none d-md-inline"
                                              jhiTranslate="vdrApp.project.properties.firstLogin">After first logging in</span>
                                    </button>
                                    <button type="button"
                                            replaceUrl="true"
                                            queryParamsHandling="merge"
                                            class="btn btn-light btn-sm" (click)="changeTherm('EVERY_LOGIN')"
                                            [ngClass]="{'btn-primary': project.therm=='EVERY_LOGIN'}">
                                        <span class="d-none d-md-inline"
                                              jhiTranslate="vdrApp.project.properties.everyLogin">After every logging in</span>
                                    </button>
                                    <button type="button"
                                            replaceUrl="true"
                                            queryParamsHandling="merge"
                                            class="btn btn-light btn-sm" (click)="changeTherm('NEVER')"
                                            [ngClass]="{'btn-primary': project.therm=='NEVER'}">
                                        <span class="d-none d-md-inline" jhiTranslate="vdrApp.project.properties.never">Never</span>
                                    </button>
                                </div>

                                <div class="mt-3">

                                    <p-editor #editor class="form-control content" name="thermContent"
                                              placeholder="{{ 'vdrApp.question.content' | translate }}"
                                              [(ngModel)]="project.thermContent" [style]="{'height':'320px'}"
                                              required>
                                        <p-header>
                                            <span class="ql-formats">
                                                <select class="ql-size">
                                                    <option value="small">{{ 'vdrApp.question.text-size.small' | translate }}</option>
                                                    <option selected>{{ 'vdrApp.question.text-size.normal' | translate }}</option>
                                                    <option value="large">{{ 'vdrApp.question.text-size.large' | translate }}</option>
                                                    <option value="huge">{{ 'vdrApp.question.text-size.huge' | translate }}</option>
                                                </select>
                                              </span>
                                            <span class="ql-formats">
                                                <button class="ql-bold" aria-label="Bold"></button>
                                                <button class="ql-italic" aria-label="Italic"></button>
                                                <button class="ql-underline" aria-label="Underline"></button>
                                                <button class="ql-strike" aria-label="Strike"></button>
                                            </span>
                                            <span class="ql-formats">
                                                <button class="ql-list" value="ordered"></button>
                                                <button class="ql-list" value="bullet"></button>
                                                <select class="ql-align" >
                                                    <option selected></option>
                                                    <option value="center" label="Center"></option>
                                                    <option value="right" label="Right"></option>
                                                    <option value="justify" label="Justify"></option>
                                                </select>
                                            </span>
                                            <span class="ql-formats">
                                                <select class="ql-color"></select>
                                                <select class="ql-background"></select>
                                            </span>
                                        </p-header>
                                    </p-editor>

                                </div>

                            </div>
                            <div class="buttons save-button">

                                <div style="text-align:right">
                                    <button type="submit" class="btn btn-primary"
                                            jhiTranslate="entity.action.save">Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'vdrApp.project.tabs.themaSettings' | translate }}" class="custom-mat-tab content-scroll">
                        <div class="generalSettings content-scroll">

                            <div class="panel">

                                <h2 jhiTranslate="vdrApp.project.personalization">Personalizacja</h2>
                                <div class="d-flex flex-row justify-content-between">
                                    <div>
                                        <label class="form-control-label">Logo</label>
                                        <div class="form-group">

                                            <div>
                                                <div>
                                                    <img
                                                        [src]="project.getProjectImageUrl()"
                                                        style="max-width: 120px;" alt="file image"/>
                                                    <button type="button"
                                                            (click)="clearInputImage('bytes', 'bytesContentType', 'fileImage')"
                                                            class="btn btn-secondary btn-xs"
                                                            style="margin-left:0px;vertical-align: top; ">
                                                        <span class="fa fa-times"></span>
                                                    </button>
                                                </div>
                                                <div class="form-text text-danger clearfix" >
                                                    <span class="pull-left">{{ fileInfo }}</span>

                                                </div>
                                                <input type="file" id="file" name="file" class="inputfile"
                                                       (change)="setFileData($event, project, 'bytes', true)"
                                                       accept="image/*"
                                                       jhiTranslate="entity.action.addimage"/>
                                                <label class="inputfile-label" for="file" jhiTranslate="global.selectFile"></label>
                                                <div class="form-text text-danger clearfix" *ngIf="fileUploadError" >
                                                    <span class="pull-left">{{ fileUploadError }}</span></div>
                                            </div>
<!--                                            <input type="hidden" class="form-control" name="bytes" id="field_bytes"-->
<!--                                                   [(ngModel)]="project.bytes"/>-->
<!--                                            <input type="hidden" class="form-control" name="bytesContentType"-->
<!--                                                   id="field_bytesContentType"-->
<!--                                                   [(ngModel)]="project.bytesContentType"/>-->
                                        </div>
                                    </div>
                                    <div style="width:50%">
                                        <label class="form-control-label" for="project_color"
                                               jhiTranslate="vdrApp.project.properties.interfaceColor">Kolor
                                            interfejsu</label>
                                        <input id="project_color" name="project_color"
                                            [(colorPicker)]="project.mainColor"
                                               [style.background]="project.mainColor"
                                               [(cpPresetColors)]="colorPresets"
                                               [cpDisableInput]="disableColorInput"
                                               [cpPosition]="'bottom'"
                                               class="color-picker-top"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div class="panel">
                                <h2 jhiTranslate="vdrApp.project.watermark.title">Watermark Configuration</h2>

                                <div class="d-flex row">
                                    <div class="form-group ml-3">
                                        <label class="form-control-label"
                                               jhiTranslate="vdrApp.project.watermark.font">Font</label>
                                        <ng-select style="border: 0;padding-left: 0;" class="form-control" id="watermarkFont" name="watermarkFont"
                                                [(ngModel)]="project.watermarkFont">
                                            <ng-option value="Arial">Arial</ng-option>
                                            <ng-option value="Verdana">Verdana</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="form-group ml-3">
                                        <label class="form-control-label"
                                               jhiTranslate="vdrApp.project.watermark.fontSize">Font Size</label>
                                        <ng-select style="border: 0;padding-left: 0;" class="form-control" id="watermarkFontSize" name="watermarkFontSize"
                                                [(ngModel)]="project.watermarkFontSize">
                                            <ng-option value="20">20</ng-option>
                                            <ng-option value="22">22</ng-option>
                                            <ng-option value="24">24</ng-option>
                                            <ng-option value="26">26</ng-option>
                                            <ng-option value="28">28</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="form-group ml-3">
                                        <label class="form-control-label" for="watermarkColor"
                                               jhiTranslate="vdrApp.project.watermark.color">Color</label>
                                        <input class="form-control" id="watermarkColor"
                                               name="watermarkColor"
                                               [(colorPicker)]="project.watermarkColor"
                                               [style.background]="project.watermarkColor"
                                               [(cpPresetColors)]="colorPresets"
                                               [cpDisableInput]="disableColorInput"
                                               [cpPosition]="'bottom'"
                                               >
                                    </div>
                                    <div class="form-group ml-3">
                                        <label class="form-control-label"
                                               jhiTranslate="vdrApp.project.watermark.opacity">Opacity</label>
                                        <ng-select style="border: 0;padding-left: 0;" class="form-control" id="watermarkOpacity" name="watermarkOpacity"
                                                [(ngModel)]="project.watermarkOpacity" [items]="opacityOptions"  bindLabel="label" bindValue="value">
                                        </ng-select>
                                    </div>
                                    <div class="form-group ml-3" style="margin-top: 7px;width: 350px;">
                                        <label class="form-control-label" for="www"
                                               jhiTranslate="vdrApp.project.watermark.text">Text</label>
                                        <input type="text" class="form-control" id="watermarkTextLine"
                                               name="watermarkTextLine"
                                               [(ngModel)]="project.watermarkTextLine" minlength="0" maxlength="50">
                                        <span style="color: var(--prime-color); float: right; margin-bottom: 5px">{{project.watermarkTextLine.length}}/50</span>
                                    </div>
                                </div>
                            </div>
                            <div class="buttons save-button">

                                <div style="text-align:right">
                                    <button type="submit" class="btn btn-primary"
                                            jhiTranslate="entity.action.save">Save
                                    </button>
                                </div>
                            </div>
                        </div>


                    </mat-tab>
                </mat-tab-group>


            </form>

        </div>


    </div>


</div>
