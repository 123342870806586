<form (ngSubmit)="createDirectory()">
    <div>
        <div fxLayout="row">
            <span mat-dialog-title fxFlex="90%" jhiTranslate="directory.new">New folder label</span>
            <button mat-icon-button fxFlex (click)="onNoClick()" type="button">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>
        <div mat-dialog-content fxLayout="column" fxLayoutAlign="center stretch" style="min-height: 117px">
            <jhi-alert-error></jhi-alert-error>
            <div>
                <label for="directory_name" class="label-style" jhiTranslate="details.folder.name">Folder name
                    label</label>
                <input id="directory_name" name="directory" type="text" [(ngModel)]="directoryName"
                       class="input-style" required minlength="1" maxlength="100" [disabled]="isBusy" specialIsAlphaNumeric>
                <span style="color: var(--prime-color); float: right;margin-right: -10px">{{directoryName.length}}/100</span>
                <span style="color:red" *ngIf="directoryName.length < 1">Minimalna ilość znaków to 1</span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
            <button mat-button (click)="onNoClick()" type="button">
                <mat-icon style="font-size: 22px">clear</mat-icon>
                <span jhiTranslate="entity.action.cancel">Cancel label</span>
            </button>
            <button mat-raised-button color="warn" cdkFocusInitial type="submit"
                    [disabled]="(!directoryName || directoryName.length<1) || isBusy">
                <mat-icon style="font-size: 22px">check</mat-icon>
                Ok
            </button>
        </div>
    </div>
</form>
