<div class="group-edit-dialog">
    <form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm">

        <div class="modal-header">

            <h4 class="modal-title" id="myUserGroupLabel" jhiTranslate="vdrApp.group.home.createOrEditLabel">Create or
                edit a User Group</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()">&times;
            </button>
        </div>

        <div class="modal-body">
            <div class="form-group">
                <label class="form-control-label" jhiTranslate="vdrApp.group.group_name" for="field_group_name">Group
                    Name</label>
                <input type="text" class="form-control" name="group_name" id="field_group_name"
                       [(ngModel)]="group.name" required minlength="1" maxlength="255"/>
                <span style="color: var(--prime-color); float: right">{{group.name.length}}/255</span>
                <span style="color:red" *ngIf="group.name.length < 1">Minimalna ilość znaków to 1</span>
            </div>
            <div class="form-group mb-0">
                <label jhiTranslate="vdrApp.group.type"></label>
                <div class="d-flex flex-row">
                    <div class="form-group d-flex flex-column group-type mb-0">
                        <div class="btn-group flex-btn-group-container mt-2">

                            <button type="button"
                                    class="btn btn-light btn-sm" (click)="chooseAdminType()"
                                    [ngClass]="{'btn-primary': adminType}">
                                <span class="fa fa-users mr-2"></span>
                                <span class="d-none d-md-inline" jhiTranslate="vdrApp.group.admins">Admins</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseUserType()"
                                    [ngClass]="{'btn-primary': !adminType}">
                                <span class="fa fa-users mr-2"></span>
                                <span class="d-none d-md-inline" jhiTranslate="vdrApp.group.users">Users</span>
                            </button>
                        </div>
                        <label class="mt-3" jhiTranslate="vdrApp.group.role">Role</label>

                        <div class="btn-group flex-btn-group-container mt-2" *ngIf="adminType">

                            <button type="button"
                                    class="btn btn-light btn-sm" (click)=" chooseRole(Role.ADMIN)"
                                    [ngClass]="{'btn-primary': group.role===Role.ADMIN}">
                                <span class="fa fa-user mr-2"></span>
                                <span class="d-none d-md-inline" jhiTranslate="vdrApp.group.fullAdmin">Admins</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)=" chooseRole(Role.ADMIN_LIMITED)"
                                    [ngClass]="{'btn-primary': group.role===Role.ADMIN_LIMITED}">
                                <span class="fa fa-user mr-2"></span>
                                <span class="d-none d-md-inline" jhiTranslate="vdrApp.group.localAdmin">Users</span>
                            </button>

                        </div>
                        <div class="btn-group flex-btn-group-container mt-2" *ngIf="!adminType">


                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)=" chooseRole(Role.USER)"
                                    [ngClass]="{'btn-primary': group.role===Role.USER}">
                                <span class="fa fa-user"></span>
                                <span class="d-none d-md-inline" jhiTranslate="vdrApp.group.user">Users</span>
                            </button>

                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)=" chooseRole(Role.USER_INDIVIDUAL)"
                                    [ngClass]="{'btn-primary': group.role===Role.USER_INDIVIDUAL}">
                                <span class="fa fa-user"></span>
                                <span class="d-none d-md-inline" jhiTranslate="vdrApp.group.userLimited">Users</span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group mb-0  d-flex flex-column group-permissions">
                        <ul class="fa-ul" *ngIf="groupPermissionsDescriptionVisible">
                            <li *ngFor="let permission of ( 'vdrApp.group.permissions.' + Role[group.role].toString() | translate )">
                                <span class="fa-li"><i class="fa fa-check"></i></span> {{permission}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="!adminType && group.role==Role.USER"
                 class="form-group d-flex flex-column">
                <label jhiTranslate="vdrApp.group.home.collaboratingGroups"></label>
                <mat-select panelClass="collaboration-select" disableOptionCentering [compareWith]="compareGroups"
                            [(ngModel)]="group.collaborationGroups" multiple name="collaborationGroups">
                    <mat-option *ngFor="let group of projectGroups" [value]="group">{{group.name}}</mat-option>
                </mat-select>
            </div>
            <div class="form-group" style="padding-top: 10px">
                <label class="form-control-label"
                       for="filed_max_number_questions"
                       jhiTranslate="vdrApp.group.home.question.limit"></label>
                <input type="number" min="0" max="1000" class="form-control" name="max_number_questions" id="filed_max_number_questions"
                       [(ngModel)]="group.maxNumberQuestions"/>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
                <span class="fa fa-ban"></span>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
            </button>
            <button type="submit" [disabled]="editForm.form.invalid || isRoleSelected() || isSaving || !containsCharactor(group.name)" class="btn btn-primary">
                <span class="fa fa-save"></span>&nbsp;<span jhiTranslate="entity.action.save">Save</span>
            </button>
        </div>
    </form>
</div>
