import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpParams, HttpResponseBase} from '@angular/common/http';
import {FileAndDir} from './directory-structure.component';
import {getIconForFilename} from 'font-awesome-filetypes';
import blobToHash from 'blob-to-hash';


@Component({
    selector: 'single-file-upload',
    templateUrl: `./single-file-upload.component.html`,
})
export class SingleFileUploadComponent implements OnInit, OnDestroy {
    @Input()
    httpUrl = 'http://localhost:8080';

    @Input()
    httpRequestParams: HttpParams | {
        [param: string]: string | string[];
    } = new HttpParams();

    @Input()
    fileAlias = 'file';

    uploadSubscription: any;

    uploadCompleted = false;

    uploadError = false;

    @Input()
    documentId: number;

    @Input()
    get file(): FileAndDir {
        return this._file;
    }

    set file(file: FileAndDir) {
        this._file = file;
        this.total = this._file.file.size;
    }

    @Input()
    set id(id: number) {
        this._id = id;
    }

    get id(): number {
        return this._id;
    }

    uploadStarted = false;
    progressPercentage = 0;
    loaded = 0;
    total = 0;
    _file: FileAndDir;
    _id: number;
    fileUploadPromise: Promise<string>;

    /** Output  */
    @Output() removeEvent = new EventEmitter<SingleFileUploadComponent>();

    @Output() uploadFinished = new EventEmitter<string>();

    constructor(
        private httpClient: HttpClient
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.uploadSubscription) {
            this.uploadSubscription.unsubscribe();
        }
    }

    getFileName() {
        let fileName = this._file.file.name;

        if (fileName.length > 100) {
            let extension = null;
            if (fileName.split('.').length > 1) {
                extension = fileName.split('.').pop();
            }
            fileName = fileName.slice(0, 90);
            if (extension) {
                fileName = fileName + '.' + extension;
            }
        }
        return fileName;
    }

    public async upload(): Promise<string> {
        if (!this._file) {
            throw 'Missing file';
        }

        this.uploadError = false;
        this.uploadStarted = true;
        const formData = new FormData();
        // const fileName = this.getFileName();
        const fileName = this._file.file.name;

        formData.set(this.fileAlias, this._file.file);
        const directoryId = (this._file.directoryId) ? this._file.directoryId.toString() : '';
        const checksum = await this.calculateChecksum(this._file.file);

        this.httpRequestParams = new HttpParams().set('directoryId', directoryId)
            .set('batchId', this._file.batchId)
            .set('checksum', checksum)
            .set('idempotencyKey', this.calculateIdempotencyKey(checksum, directoryId, this._file.batchId, fileName));

        if (this.documentId) {
            this.httpRequestParams = this.httpRequestParams.set('documentId', this.documentId.toString());
        }
        console.log('start upload:' + this._file.file.name + ' ' + new Date(), JSON.stringify(this._file, null, 2));

        this.fileUploadPromise = new Promise<string>((resolve, reject) => {
            this.httpClient.post(this.httpUrl, formData, {
                observe: 'events',
                params: this.httpRequestParams,
                reportProgress: true,
                responseType: 'text'
            }).subscribe((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.progressPercentage = Math.floor(event.loaded * 100 / event.total);
                    this.loaded = event.loaded;
                    this.total = event.total;

                    console.log('progress', this.progressPercentage, this.loaded, this.total);
                } else if (event instanceof HttpResponseBase) {
                    if (!event.ok && event.status !== 304) {
                        throw Error('Upload failed');
                    }
                    resolve(this.onUploadCompleted());
                }
            }, ((e: HttpErrorResponse) => {
                // properly handle not modified responses
                if (e.status === 304) {
                    console.log('Handling not modified response for file upload');
                    resolve(this.onUploadCompleted());
                }

                this.uploadFinished.emit(null);
                this.uploadCompleted = false;
                this.uploadError = true;

                console.error(e);
                reject(e);
            }));
        });

        return this.fileUploadPromise;
    }

    private onUploadCompleted() {
        this.uploadCompleted = true;
        this.progressPercentage = 100;
        console.log('end upload:' + this._file.file.name + ' ' + new Date());
        this.uploadFinished.emit(this._file.directoryId + '/' + this._file.file.name);
        return this._file.directoryId + '/' + this._file.file.name;
    }

    public remove(): void {
        this.removeEvent.emit(this);
    }

    getIconTag(name: string): string {
        return getIconForFilename(name.trim());
    }

    private calculateIdempotencyKey(checksum: string, directoryId: string, batchId: string, filename: string): string {
        const valueToHash = [checksum, directoryId, batchId, filename].join('|');
        return btoa(unescape(encodeURIComponent(valueToHash)));
    }

    private async calculateChecksum(file: File): Promise<string> {
        if (location.protocol === 'http:') {
            return '';
        }
        return blobToHash('sha256', file)
            .then((value) => value)
            .catch((reason) => {
                console.warn('Cannot create checksum, file most likely won\'t be uploaded properly', reason);
                return '';
            });
    }
}
