import {User} from '../user/user.model';
import {BaseEntity} from '../index';

export class Group {
    constructor(
        public id?: number,
        public name: string = '',
        public users?: User[],
        public role?: Role,
        public admin?: boolean,
        public collaborationGroups?: Group[],
        public maxNumberQuestions?: number
    ) {

    }
}

export  enum Role {
    'ADMIN',
    'ADMIN_LIMITED',
    'USER',
    'USER_INDIVIDUAL'
}
